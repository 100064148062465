
























































































































































































import {
  PublishedCommunityProjectDto,
  CommunityActionButtonType,
  ErrorDto,
  ProjectTagDto,
  CommunityProjectType,
  FullProjectCategoryDto
} from '../utils/api';
import Vue from 'vue';
import http, { getCabinetUrl } from '../utils/http';
import { AxiosError } from 'axios';
import { VisibleMessageNameType } from '../store/ui';
import { mapState } from 'vuex';
import CustomTemplate from '../components/Publish/CustomTemplatePublish.vue';
import HeaderComponent from '../components/Header/Header.vue';
import Footer from '../components/Footer/Footer.vue';
import HeaderLogIn from '../components/Header/Header.vue';
import cookies from '../utils/cookies';
import copyToClipboard from '../utils/copy_to_clibpoard';
import { debounce } from 'lodash';

import Limit from './components/Limit.vue';
import AirTemplate from './components/Template.vue';
import Modal from './components/Modal.vue';
import apiClient, {
  handleHttpErrors,
  handleFormErrors,
  MasonryProjectsFilter,
  SavingMasonryProjectItemDto
} from '../utils/apiClient';
import SocialAuth, { GoogleLoginProvider } from '../utils/social-auth';
import ProjectsFadeGrid from './components/Projects-fade-grid.vue';
import Storage from '../utils/itmeo/storage';
import Cta from './components/Cta.vue';
declare module 'vue/types/vue' {
  interface Vue {
    $mautic: any;
  }
}
export enum TypeModal {
  AuthorizationType = 'AuthorizationType',
  Authorization = 'Authorization',
  EmailConfirm = 'EmailConfirm',
  ActionPerformed = 'ActionPerformed',
  SendMessagePopup = 'SendMessagePopup'
}

export interface MauticOptions {
  email?: string;
  points?: number;
  tags?: string[];
  firstname?: string;
}

export function getRedirectLoginUrl(): string {
  let url = `/login?redirectUrl=${location.pathname}`;

  if (location.hostname == 'localhost') url = `http://localhost:3000${url}`;

  return url;
}

export function getRedirectUrl(): string {
  let url = `/`;

  if (location.hostname == 'localhost') url = `http://localhost:3000/`;

  return url;
}

export default Vue.extend({
  components: {
    CustomTemplate,
    HeaderComponent,
    HeaderLogIn,
    Limit,
    AirTemplate,
    Modal,
    Footer,
    ProjectsFadeGrid,
    Cta
  },
  metaInfo() {
    const { title, keywords, description, isScreenshot, baseURL, url, domain } = this.pageInfo;
    const { socialImage, faviconImage } = this.projectSettings;
    const socialTitle = this.socialTitle;
    const fontLinks =
      this.pageSettings.googleFonts && this.pageSettings.googleFonts.fonts
        ? this.pageSettings.googleFonts.fonts.map((font: any) => {
            return {
              rel: 'stylesheet',
              href: `https://fonts.googleapis.com/css?family=${font}`
            };
          })
        : [];
    fontLinks.push({
      rel: 'icon',
      type: 'image/x-icon',
      href:
        faviconImage && faviconImage.fullPath
          ? faviconImage.fullPath
          : 'https://d10z6vylcn8rcd.cloudfront.net/Desktop/favicon_a5e519b4-ff4e-41b4-80cc-445842c3b310.png'
    });
    let socialUrl = [domain, baseURL] //, url
      .filter(x => x)
      .map(x => x.replace(/^\//g, ''))
      .join('/');

    let meta = [
      {
        hid: 'og:title',
        property: 'og:title',
        content: socialTitle,
        vmid: 'og:title'
      },
      {
        hid: 'twitter:title',
        property: 'twitter:title',
        content: socialTitle,
        vmid: 'twitter:title'
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
        vmid: 'og:description'
      },
      {
        hid: 'twitter:description',
        property: 'twitter:description',
        content: description,
        vmid: 'twitter:description'
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: `https://${socialUrl}`,
        vmid: 'og:url'
      },
      {
        hid: 'twitter:url',
        property: 'twitter:url',
        content: `https://${socialUrl}`,
        vmid: 'twitter:url'
      },
      {
        hid: 'twitter:domain',
        property: 'twitter:domain',
        content: `itmeo.com`,
        vmid: 'twitter:domain'
      },
      {
        hid: 'twitter:card',
        property: 'twitter:card',
        content: `summary_large_image`,
        vmid: 'twitter:card'
      },
      {
        name: 'description',
        content: description,
        vmid: 'description'
      }
    ];

    if (keywords) {
      meta.push({
        name: 'keywords',
        content: keywords,
        vmid: 'keywords'
      });
    }
    if (isScreenshot) {
      meta.push({
        name: 'robots',
        content: 'nofollow, noindex',
        vmid: 'robots'
      });
    }

    if (socialImage) {
      meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: socialImage.fullPath,
        vmid: 'og:image'
      });
      meta.push({
        hid: 'og:image:type',
        property: 'og:image:type',
        content: 'image/png',
        vmid: 'og:image:type'
      });
      meta.push({
        hid: 'twitter:image',
        property: 'twitter:image',
        content: socialImage.fullPath,
        vmid: 'twitter:image'
      });
    }

    return {
      title,
      link: fontLinks,
      meta
    };
  },
  data() {
    const google = new GoogleLoginProvider(this.$gAuth);
    return {
      isMoreText: true,
      TypeModal,
      VisibleMessageNameType,
      google: new SocialAuth(google),
      CommunityActionButtonType,
      isVisible: false,
      typeActionButton: CommunityActionButtonType.Form,
      isModal: false,
      isLikeClick: false,
      stepModal: TypeModal.AuthorizationType,
      projectHasLike: { hasLike: false },
      isProcessing: false,
      isLogin: false,
      downloadProgress: 0,
      isDownloadStarted: false,
      link: '',
      hasNextPage: false,
      isLoadingNextPage: false,
      filter: {} as MasonryProjectsFilter,
      projects: [] as SavingMasonryProjectItemDto[],
      categories: [] as FullProjectCategoryDto[],
      tags: [] as ProjectTagDto[],
      isElips: false
    };
  },
  computed: {
    ...mapState({
      pageSettings: (state: any) => state.page.settings,
      page: (state: any) => state.page,
      pageInfo: (state: any) => state.page.info,
      isScreenshot: (state: any) => state.page.info.isScreenshot,
      isIncreasedProjectLimits: (state: any) => state.page.isIncreasedProjectLimits,
      projectSettings: (state: any) => state.page.projectSettings,
      socialTitle: (state: any) => state.page.socialTitle,
      communityProject: (state: any) => <PublishedCommunityProjectDto>state.page.communityProject,
      isUserLoggedIn: (state: any) => state.auth.isUserLoggedIn,
      user: (state: any) => state.auth.user,
      visibleMessageName: (state: any) => <VisibleMessageNameType>state.ui.visibleMessageName,
      messageText: (state: any) => state.ui.messageText
    }),
    favoriteTemplates(): any[] {
      return Storage.getFavoriteTemplates();
    },
    srcArrowLeft() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/arrow-left.svg';
    },
    srcFile() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/file.svg';
    },
    fileName(): string | undefined {
      let fileName = this.communityProject.actionButton.fileName;
      if (!fileName || fileName.length < 15) return fileName;

      return fileName.substr(0, 5) + '...' + fileName.substr(fileName.length - 7, 7);
    },
    isSimpleProduct(): boolean {
      return this.page.isProduct && this.communityProject.communityProjectType == CommunityProjectType.Simple;
    },
    srcDefaultAvatar() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/avatar.jpg';
    },
    srcsetDefaultAvatar() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/avatar@2x.jpg 2x';
    },
    srcShare() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/icon-share.svg';
    },
    srcLike() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/like.svg';
    },
    srcActiveLike() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/activeLikeProject.svg';
    },
    srcRight() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/icon-right.svg';
    },
    srcFollow() {
      return (
        process.env.AMAZON_BUCKET_NAME +
        '/Defaults/' +
        (this.communityProject.owned ? 'editProfile' : this.communityProject.followed ? 'unfollow' : 'follow') +
        '.svg'
      );
    },
    buttonFollowText(): String {
      return this.communityProject.owned ? 'Edit' : this.communityProject.followed ? 'Unfollow' : 'Follow';
    }
  },

  created() {
    if ((<any>process).client) {
      document.addEventListener('keyup', this.closeModal);
      /*  const gauthOption = {
        clientId:
          "278325185500-n0kcp1er19f8qn136pkrfcae9q6ijd90.apps.googleusercontent.com",
        scope: "profile email https://www.googleapis.com/auth/plus.login",
      };
      Vue.use(GAuth, gauthOption); */
    }
  },
  beforeDestroy() {
    if ((<any>process).client) {
      document.removeEventListener('keyup', this.closeModal);
    }
  },

  async mounted() {
    try {
      if ((<any>process).client) {
        const policyCookie = cookies.getItem('a_cp');
        if (!policyCookie) {
          this.isVisible = true;
        }
      }
      if (!this.communityProject) return;
      if (this.page.isBlog) {
        const filter: MasonryProjectsFilter = {
          IsAll: false,
          IsRandom: true,
          PageNumber: 1,
          PageSize: 12,
          ExcludeProjectIds: [this.communityProject.projectId],
          RandomId: null
        };
        const { data } = await apiClient.feed.feedGetMasonryProjects(filter);

        const projects = data.items.map(x => ({
          ...x,
          isSaved: this.favoriteTemplates.includes(x.id.toString()) || false
        }));

        filter.RandomId = data.randomId;
        const hasNextPage = data.pagination.hasNextPage;
        this.projects = projects;
        this.filter = filter;
        this.hasNextPage = hasNextPage;
        const { data: categories } = await apiClient.projectCategories.projectCategoriesGet();
        this.categories = categories.items;
      }
      this.projectHasLike.hasLike = this.communityProject.hasLike;
      this.typeActionButton = this.communityProject.actionButton.type;
      const { currentUserAuthorized, author, projectId } = this.communityProject;
      let options: MauticOptions = { tags: ['visited-airtap'] };
      if (currentUserAuthorized) {
        if (this.user) {
          const { email } = this.user;
          options.email = email;
        }
      }
      if (!this.projectSettings) return;

      if ((<any>process).client) {
        this.$mautic.pageView(options);
        await apiClient.profiles.profilesSetProjectPageView(projectId, this.page.pageId);
        const big = document.querySelector<HTMLElement>('.info-block__main__text');
        this.isElips = (big && big.offsetHeight < big.scrollHeight) || false;

        if (big) window.addEventListener('resize', this.calcElip, true);
      }
    } catch (error) {
      handleHttpErrors(this.$store);
    }
  },

  methods: {
    calcElip() {
      const newBig = document.querySelector<HTMLElement>('.info-block__main__text');
      this.isElips = (newBig && newBig.offsetHeight < newBig.scrollHeight) || false;
    },
    share($event: Event) {
      $event.preventDefault();
      $event.stopPropagation();
      copyToClipboard(window.location.href);

      this.$store.commit('ui/changeVisibleMessageName', VisibleMessageNameType.Success);
      this.$store.commit('ui/changeMessageText', 'Link copied successfully');
      setTimeout(() => {
        this.$store.commit('ui/changeMessageText', '');
      }, 3000);
    },
    getNextPage() {
      if (!this.isUserLoggedIn || !this.hasNextPage || this.isLoadingNextPage) return;
      this.isLoadingNextPage = true;
      if (this.filter.PageNumber) this.filter.PageNumber++;

      apiClient.feed.feedGetMasonryProjects(this.filter).then(({ data }) => {
        this.hasNextPage = data.pagination.hasNextPage;

        data.items.forEach(x => {
          this.projects.push({
            ...x,
            isSaved: this.favoriteTemplates.includes(x.id)
          });
        });

        this.isLoadingNextPage = false;
      });
    },
    openModal() {
      if (this.isUserLoggedIn) {
        this.sendEmail();
      } else {
        location.href = getRedirectLoginUrl();
        // this.isModal = true;
        //this.stepModal = TypeModal.AuthorizationType;
      }
    },
    openSendMessagePopup() {
      if (this.isUserLoggedIn) {
        this.isModal = true;
        this.stepModal = TypeModal.SendMessagePopup;
      } else {
        location.href = getRedirectLoginUrl();
        return;

        //this.isModal = true;
        //this.stepModal = TypeModal.AuthorizationType;
      }
    },
    sendMessage() {
      this.isModal = true;
      this.stepModal = TypeModal.ActionPerformed;
    },
    async sendEmail() {
      this.toggleSendEmail(
        this.communityProject.projectId,
        this.typeActionButton,
        this.$data,
        this.downloadFile,
        this.$store,
        this.$mautic,
        this.user.email
      );
    },
    toggleSendEmail: debounce(async function(
      projectId: number,
      typeActionButton: CommunityActionButtonType,
      $data: any,
      downloadFile: any,
      $store: any,
      $mautic,
      email: string
    ) {
      if ($data.isProcessing) return;

      try {
        $data.isProcessing = true;
        const data = await apiClient.publish.publishSubscribe(projectId);
        $data.isProcessing = false;
        if (typeActionButton == CommunityActionButtonType.Link) {
          $data.link = (<any>data).data.url;
        }
        if (typeActionButton == CommunityActionButtonType.File) {
          downloadFile();
          return;
        }
        $mautic.pageView({
          email: email,
          points: 11,
          tags: ['subscribed-for-product-airtap']
        });
        $data.isModal = true;
        $data.stepModal = TypeModal.ActionPerformed;
      } catch (error) {
        handleHttpErrors($store, null, () => {
          $data.isProcessing = false;
        });
      }
    },
    800),
    async downloadFile() {
      const start = new Date();
      this.isDownloadStarted = true;
      const downloadProgress = (progressEvent: any) => {
        this.downloadProgress =
          progressEvent.total !== 0 ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 0;
      };
      http
        .downloadFile(`/api/publish/${this.communityProject.projectId}/file`, downloadProgress)
        .then(response => {
          this.downloadFileByLink(response.data, this.communityProject.actionButton.fileName || 'file');
          setTimeout(() => {
            this.downloadProgress = 0;
            this.isDownloadStarted = false;
          }, 3000);
          this.isModal = true;
          this.isProcessing = false;
          this.stepModal = TypeModal.ActionPerformed;
          this.$mautic.pageView({
            email: this.user.email,
            points: 11,
            tags: ['download-files-from-airtap']
          });
        })
        .catch(async error => {
          handleHttpErrors(this.$store, this.$data, () => {
            this.isDownloadStarted = false;
            this.downloadProgress = 0;
          });
        });
    },
    downloadFileByLink(fileBytes: any, fileName: string) {
      const url = window.URL.createObjectURL(new Blob([fileBytes]));
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.click();
    },
    closeModal(event: any) {
      if (event) {
        event.preventDefault();

        if (event.key) {
          if (event.key === 'Escape') {
            this.cleanDataMadal;
          }
        }
      }
    },
    googleAuthorize() {
      try {
        this.google.autheticated(async (isRegistered: boolean) => {
          await this.$store.dispatch('auth/getUserInfo');
          if (this.isLikeClick) this.cleanDataMadal();
          else this.sendEmail();

          let options: MauticOptions = {
            email: this.user.email,
            points: isRegistered ? 10 : 7,
            tags: [isRegistered ? 'registered-airtap' : 'logged-in-airtap']
          };

          if (isRegistered && this.user.fullName) {
            options.firstname = this.user.fullName;
          }

          this.$mautic.pageView(options);
        });
      } catch (error) {
        handleHttpErrors(this.$store, this.$data);
      }
    },
    changeLogin() {
      this.isLogin = !this.isLogin;
    },
    changestepModal(type: any) {
      this.stepModal = type;
    },
    cleanDataMadal() {
      this.isModal = false;
      this.stepModal = TypeModal.AuthorizationType;

      this.isLikeClick = false;
    },
    getErrorData(errorResponse: AxiosError) {
      if (!errorResponse.response) return [];

      return <ErrorDto[]>errorResponse.response.data;
    },
    async authRegister() {
      this.stepModal = TypeModal.EmailConfirm;
    },
    handleCloseButtonClick(event: Event) {
      if (!(<any>process).client) return;
      if (event) event.preventDefault();

      cookies.setItem('a_cp', true, Infinity);
      this.isVisible = false;
    },
    async likeProject(e: string) {
      if (!this.communityProject) return;
      this.isLikeClick = true;
      if (!this.isUserLoggedIn) {
        location.href = getRedirectLoginUrl();

        // this.isModal = true;
        //this.stepModal = TypeModal.AuthorizationType;
        return;
      }
      this.isLikeClick = false;
      const { author } = this.communityProject;
      // <any>author.userName,
      this.communityProject.hasLike = !this.communityProject.hasLike;
      this.toggleProjectLike(this.communityProject, this.projectHasLike, this.$store);
    },
    toggleProjectLike: debounce(async function(
      project: PublishedCommunityProjectDto,
      projectHasLike: any,
      $store: any
    ) {
      if (projectHasLike.hasLike == project.hasLike) return;

      try {
        await apiClient.profiles.profilesToggleProjectLike(project.author.userName, project.projectId);
        projectHasLike.hasLike = project.hasLike;
      } catch (error) {
        handleHttpErrors($store, null, () => {
          project.hasLike = !project.hasLike;
        });
      }
    },
    800),
    async followUser() {
      if (!this.communityProject) return;

      const { author } = this.communityProject;

      if (!this.isUserLoggedIn) {
        location.href = getRedirectLoginUrl();
        return;
      }
      if (this.communityProject.owned) {
        location.href =
          getRedirectUrl() + `editor/projects/${this.communityProject.projectId}/pages/${this.page.pageId}`;

        return;
      }
      try {
        await apiClient.profiles.profilesToggleProfileFollow(<any>author.userName);
        this.communityProject.followed = !this.communityProject.followed;
        if (this.communityProject.followed)
          this.$mautic.pageView({
            email: this.user.email,
            tags: ['following-1-or-more']
          });
      } catch (error) {
        handleHttpErrors(this.$store);
      }
    },
    async authLogin(data: any) {
      if (data.isEmailConfirm) this.stepModal = TypeModal.EmailConfirm;
      else {
        if (data.isConfirmedEmail) {
          this.isModal = false;
          await this.$store.dispatch('auth/getUserInfo');
          if (this.isLikeClick) this.cleanDataMadal();
          else this.sendEmail();
        } else {
          this.stepModal = TypeModal.EmailConfirm;
        }
      }
    },
    goToPlan() {
      location.href = `${getCabinetUrl()}/dashboard/plans`;
    }
  }
});
