export default class LocalStorage {
  /**
   * Set value by key
   * @param {string} key - key for set value
   * @param {string} value - value
   */
  set(key: string, value: string) {
    if ((<any>process).browser) {
      localStorage.setItem(key, value);
    }

    return null;
  }

  remove(key: string) {
    if ((<any>process).browser) {
      return localStorage.removeItem(key);
    }
  }

  /**
   * Get value by key
   * @param {string} key - key for get value
   * @returns {string} string from localStorage
   */
  get(key: string) {
    if ((<any>process).browser) {
      return localStorage.getItem(key);
    }

    return null;
  }
}
