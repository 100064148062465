

















import Vue from 'vue'
import { mapState } from 'vuex'
import { AccountDto } from '../../utils/api'
import { getAmazonDefaultsURL } from '../../utils/images'
import NextIcon from './Svg/NextIcon.vue'
type ItemDto = {
  text: string
  link: string
  iconName?: string
}

export default Vue.extend({
  components: {
    NextIcon,
  },
  props: {
    isMain: Boolean,
  },
  data() {
    return {
      list: [
        {
          text: 'Itmeo',
          link: '/itmeo',
          iconName: this.isMain
            ? 'img/main/icons/social/white/logo-itmeo-small.svg'
            : getAmazonDefaultsURL('logo-itmeo-small.svg'),
        },
        {
          text: 'Instagram',
          link: 'https://www.instagram.com/itmeo.official/',
          iconName: this.isMain ? 'img/main/icons/social/white/instagram.svg' : getAmazonDefaultsURL('instagram.svg'),
        },
        {
          text: 'Facebook',
          link: 'https://www.facebook.com/itmeocom',
          iconName: this.isMain ? 'img/main/icons/social/white/facebook.svg' : getAmazonDefaultsURL('facebook.svg'),
        },
        {
          text: 'Twitter',
          link: 'https://twitter.com/itmeohq',
          iconName: this.isMain ? 'img/main/icons/social/white/x-logo.svg' : getAmazonDefaultsURL('x-logo.svg'),
        },
      ] as ItemDto[],
    }
  },
  computed: {
    ...mapState({
      isUserAuth: (store: any) => store.auth.isUserLoggedIn,
      user: (store: any) => <AccountDto>store.auth.user,
    }),
  },
})
