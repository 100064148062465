







import Vue from 'vue';
export default Vue.extend({
  computed: {
    srcAstronavt() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/astronavt.png';
    },
    srcsetAstronavt() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/astronavt@2x.png 2x';
    }
  }
});
