import { BaseMasonryProjectDto } from "../api";

/**
 * get project name from url
 */
export function getProjectName(url: string) {
  if (!url) return "";

  return url
    .split("-")
    .map(x => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");
}

/**
 * create url
 */
export function createProjectUrl(project: BaseMasonryProjectDto) {
  if (!project || !project.name) return "";

  const projectName = convertStringForUrl(project.name);

  const projectTypeName = convertStringForUrl(project.typeName || "");

  return `/market/${projectTypeName}/${projectName}-${project.id}`;
}

/**
 * create project download file name
 */
function createProjectDownloadFileName(project: BaseMasonryProjectDto) {
  if (!project || !project.name) return "";

  const projectName = convertStringForUrl(project.name);

  return `${projectName}-by-itmeo.zip`;
}

/**
 * create project a download link
 */
export function downloadProjectByLink(
  fileBytes: any,
  project: BaseMasonryProjectDto
) {
  const url = window.URL.createObjectURL(new Blob([fileBytes]));
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", createProjectDownloadFileName(project));
  link.click();
}

export function createProjectTypeUrl(project: BaseMasonryProjectDto) {
  if (!project || !project.typeName) return "";

  const projectTypeName = convertStringForUrl(project.typeName);

  return `/market/${projectTypeName}`;
}

export function getIdFromURL(url: any) {
  if (!url) return "";

  return +url.split("-").pop();
}

function convertStringForUrl(str: string) {
  return str
    .replace(/&/g, "and")
    .split(" ")
    .map(x =>
      x
        .replace(",", "")
        .replace(".", "")
        .replace("/", "_")
    )
    .join("-")
    .toLowerCase();
}

export function getProjectPictureForMeta(pictures: any[]) {
  if (!pictures || !pictures.length)
    return "https://d10z6vylcn8rcd.cloudfront.net/Desktop/open graph_cfad6321-559d-4549-a1f3-a2732fa565f3.png";

  return pictures.find(x => x.isCommon).url;
}

//TODO: union both methods createPicturesHtml and picturesHtml to one
export function createPicturesHtml(pictures: any[], isFeed: boolean) {
  if (!pictures || !pictures.length) return [];

  let picturesHtml = pictures
    .filter(x => x.isFeed == isFeed)
    .reduce(
      (result, picture) => {
        const encodeUrl = picture.url.replace(/ /g, "%20");
        switch (picture.type) {
          case "Mobile":
            if (!picture.isWebP && !picture.isJp2) break;

            var type = picture.isWebP ? "webp" : "jp2";
            result[type].push(`${encodeUrl} 660w`);
            break;

          case "Retina":
            if (!picture.isWebP && !picture.isJp2) break;

            var type = picture.isWebP ? "webp" : "jp2";
            result[type].push(`${encodeUrl} 2x`);
            break;

          // case 'Desktop':
          //   if (!picture.isWebP) break

          //   var type = picture.isWebP ? 'webp' : 'jp2'
          //   result[type].push(`${encodeUrl} 1x`)
          //   break
        }

        return result;
      },
      { webp: [], jp2: [] }
    );
  return Object.keys(picturesHtml).map(key => {
    const srcset = picturesHtml[key].join(", ");
    const type = `image/${key}`;
    return { srcset, type };
  });
}

export function picturesHtml(pictures: any[]) {
  if (!pictures || !pictures.length) return "";

  let picturesHtml = pictures.reduce(
    (result, picture) => {
      const encodeUrl = picture.url.replace(/ /g, "%20");
      switch (picture.type) {
        case "Mobile":
          if (!picture.isWebP && !picture.isJp2) break;

          var type = picture.isWebP ? "webp" : "jp2";
          result[type].push(`${encodeUrl} 660w`);
          break;

        case "Retina":
          if (!picture.isWebP && !picture.isJp2) break;

          var type = picture.isWebP ? "webp" : "jp2";
          result[type].push(`${encodeUrl} 2x`);
          break;

        case "Desktop":
          if (!picture.isWebP) break;

          var type = picture.isWebP ? "webp" : "jp2";
          result[type].push(`${encodeUrl} 1x`);
          break;
      }

      return result;
    },
    { webp: [], jp2: [] }
  );

  return Object.keys(picturesHtml)
    .map(key => {
      const srcset = picturesHtml[key].join(", ");
      const type = `image/${key}`;
      return `<source data-srcset="${srcset}" type="${type}">`;
    })
    .join("");
}
