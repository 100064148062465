






import Vue from "vue";
import { PublishedProjectPageDto } from "./utils/api";
import { IPublishAPI, serviceCollection } from "./components/Publish/types";
import { PublishAPI } from "./utils/publish-api";

export default Vue.extend({
  created() {
    serviceCollection.register<IPublishAPI, PublishAPI>(new PublishAPI());
  },
  mounted() {
    const { projectSettings } = <PublishedProjectPageDto>this.$store.state.page;
    if (projectSettings && projectSettings.facebookPixelId) {
      (<any>Vue).analytics.fbq.init(projectSettings.facebookPixelId, {});
    }
  },
  metaInfo() {
    const { projectSettings } = <PublishedProjectPageDto>this.$store.state.page;
    return projectSettings
      ? {
          link: [
            {
              rel: "icon",
              type: "image/x-icon",
              href:
                projectSettings &&
                projectSettings.faviconImage &&
                projectSettings.faviconImage.fullPath
                  ? projectSettings.faviconImage.fullPath
                  : "https://d10z6vylcn8rcd.cloudfront.net/Desktop/favicon_a5e519b4-ff4e-41b4-80cc-445842c3b310.png"
            }
          ]
        }
      : {};
  }
});
