
































import Vue from 'vue';
export default Vue.extend({
  computed: {
    srcDefaultLimit() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/project-limit.jpg';
    },
    srcsetDefaultLimit() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/project-limit@2x.jpg 2x';
    }
  }
});
