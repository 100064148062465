











import Vue from 'vue'
import { mapState } from 'vuex'

import apiClient, { handleHttpErrors } from '../../utils/apiClient'
export default Vue.extend({
  computed: {
    ...mapState({
      isUserLoggedIn: (state: any) => <boolean>state.auth.isUserLoggedIn,
    }),
  },
  methods: {
    logout(event: Event) {
      if (event) event.preventDefault()

      if (!this.isUserLoggedIn) {
        this.$router.push('/login')
        return
      }

      apiClient.auth
        .authLogout()
        .then(() => {
          //this.$store.commit('auth/changeIsUserLoggedIn', false)
          // this.$store.commit('auth/changeUser', null)
          location.href = '/login'
        })
        .catch((error) => handleHttpErrors(this.$store))
    },
  },
})
