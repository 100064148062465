























































































































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import MobileMenu from './MobileMenu.vue'
import AuthButton from './AuthButton.vue'
import UserInfo from './UserInfo.vue'
import ProfileDropdown from './ProfileDropdown.vue'
import { FullProjectCategoryDto } from '../../utils/api'
import apiClient from '../../utils/apiClient'

type HeaderProjectTypeDto = FullProjectCategoryDto & {
  isActive: boolean
  isSpecial?: boolean
}
export default Vue.extend({
  components: {
    MobileMenu,
    AuthButton,
    UserInfo,
    ProfileDropdown,
  },
  data() {
    return {
      activeMobileMenu: false,
      focused: false,
      query: '',
      isWindowScrolled: true,
      windowScrollPos: 0,
      isShowDropdown: false,
      isOver: false,
      isProfileDropdownVisible: false,
      isShowMore: false,
      templateTypes: [] as HeaderProjectTypeDto[],
    }
  },
  props: {
    isDark: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isDashboard: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    ...mapState({
      isUserAuth: (store: any) => store.auth.isUserLoggedIn,
      user: (store: any) => store.auth,
      isUserExist: (store: any) => store.auth.user.user,
    }),
    showTagsRow(): boolean {
      return (<any>this.$route.meta).hideTagsRow !== true
    },
    /*     isDashboard(): boolean {
      const routePathParts = this.$route.path.split('/')
      if (routePathParts && routePathParts.length > 0) {
        return this.isUserAuth && (routePathParts[1] == 'dashboard' || routePathParts[1] == 'get-started')
      } else return false
    }, */
    activeDropdownItem(): string {
      if (this.$route.name === 'dashboard') return 'dashboard'
      if (this.$route.name === 'manifest') return 'manifest'
      if (this.$route.name === 'pricing') return 'pricing'
      if (this.$route.name === 'feed') return 'feed'
      if (this.$route.name === 'settings-section' && this.$route.params.section === 'help') return 'help'

      if (this.$route.name === 'settings-section' && this.$route.params.section === 'plans') return 'plans'

      if (this.$route.name === 'settings-section') return 'settings'

      return ''
    },
    isFixed(): boolean {
      return this.$route.path === '/get-started'
    },
  },
  async created() {
    if (!(<any>process).client) return
    document.addEventListener('click', this.hideProfileDropdown)
    document.addEventListener('keyup', this.hideProfileDropdown)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideProfileDropdown)
    document.removeEventListener('keyup', this.hideProfileDropdown)
  },
  async mounted() {
    const { data } = await apiClient.projectCategories.projectCategoriesGet()

    this.templateTypes = [...data.items.map((item) => ({ ...item, isActive: false }))]
    if ((<any>process).client) {
      this.windowScrollPos = window.pageYOffset
      this.isWindowScrolled = this.windowScrollPos > 20
      this.getScrollPosition()
      this.$nextTick(() => {
        this.setOver()

        window.addEventListener('resize', this.setOver)
      })
    }

    document.addEventListener('click', (e) => {
      if (
        (<any>e).target &&
        (<any>e).target.id !== 'more' &&
        (!(<any>e).target.parentElement || (<any>e).target.parentElement.id !== 'more') &&
        (!(<any>e).target.parentElement ||
          !(<any>e).target.parentElement.parentElement ||
          (<any>e).target.parentElement.parentElement.id !== 'more')
      )
        document.querySelectorAll('.menu-dropdown_visible').forEach((o) => {
          o.classList.remove('menu-dropdown_visible')
        })
    })
  },
  methods: {
    isActiveCategory(category: string) {
      const routePathParts = this.$route.path.split('/')
      if (routePathParts && routePathParts.length > 2) {
        return routePathParts[1] == 'market' && routePathParts[2].toLowerCase() == category.toLowerCase()
      } else return false
    },
    hideProfileDropdown(event: any) {
      if (event) {
        if (
          event.key === 'Escape' ||
          // These are exceptions: the blocks on what click should NOT close the profile dropdown
          (!event.target.closest('.header__user-info') &&
            !event.target.closest('.user-info__profile-dropdown') &&
            !event.target.closest('.header__profile-dropdown') &&
            !event.target.closest('.header__profile-dropdown-toggler')) ||
          // These are blocks on what click SHOULD close the profile dropdown
          event.target.classList.contains('profile-dropdown__menu-item') ||
          event.target.closest('.profile-dropdown__menu-item') ||
          event.target.classList.contains('profile-dropdown__social-menu-item') ||
          event.target.closest('.profile-dropdown__social-menu-item') ||
          event.target.closest('.profile-dropdown__action') ||
          event.target.closest('.user-info__notification')
        ) {
          this.isProfileDropdownVisible = false
        }
      }
    },
    toggleProfileDropdown(event: any) {
      if (event) event.preventDefault()

      this.isProfileDropdownVisible = !this.isProfileDropdownVisible
    },
    setOver() {
      this.isOver = false
      if (!this.$refs.elementTemplateTypes) {
        return
      }
      var widthContainer = (this.$refs.elememtTagsRow as HTMLElement).offsetWidth
      var widthTemplateTypes = (this.$refs.elementTemplateTypes as HTMLElement).offsetWidth
      if (widthTemplateTypes > widthContainer - 80) this.isOver = true
      const options = {
        root: document.querySelector('.tags-row-list'),
        rootMargin: '0px',
        threshold: [0, 0.5, 1],
      }

      let observer = new IntersectionObserver((entries, showDropdown) => {
        let isMore = false

        const elMore = document.getElementById('more')
        if (elMore && elMore.parentNode) elMore.parentNode.removeChild(elMore)
        entries.forEach((entry) => {
          const elDropdown = document.getElementById(`menu-dropdown_${entry.target.id}`)
          if (entry.intersectionRatio !== 1) {
            entry.target.classList.add('hidden')

            if (elDropdown) elDropdown.classList.remove('menu-dropdown_item-hidden')

            // this.isShowMore = isMore
            if (!isMore) {
              isMore = true

              const menu = document.querySelector(`.menu-dropdown`)
              const $newLi = document.createElement('li')
              $newLi.textContent = 'more'
              $newLi.onclick = function () {
                if (menu) {
                  menu.classList.add('menu-dropdown_visible')
                  menu.setAttribute(
                    'style',
                    `left:${
                      ($newLi.getBoundingClientRect().right || document.body.getBoundingClientRect().width) -
                      menu.getBoundingClientRect().width
                    }px;`
                  )
                }
              }
              $newLi.classList.add('tags-nav__item')
              $newLi.id = 'more'
              const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
              const iconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')

              iconSvg.setAttribute('fill', 'none')
              iconSvg.setAttribute('viewBox', '0 0 8 6')
              iconSvg.classList.add('tags-nav__item-icon')

              iconPath.setAttribute(
                'd',
                'M3.99999 5.28011C3.85661 5.28011 3.71325 5.22537 3.60394 5.11611L0.164113 1.67625C-0.0547045 1.45743 -0.0547045 1.10266 0.164113 0.883926C0.382843 0.665197 0.737548 0.665197 0.956383 0.883926L3.99999 3.92771L7.04361 0.884033C7.26243 0.665303 7.61709 0.665303 7.83581 0.884033C8.05473 1.10276 8.05473 1.45754 7.83581 1.67636L4.39603 5.11622C4.28667 5.22549 4.14331 5.28011 3.99999 5.28011Z'
              )
              iconPath.setAttribute('fill', '#7b22ec')

              iconSvg.appendChild(iconPath)

              $newLi.appendChild(iconSvg)
              entry.target.before($newLi)
            }
          } else {
            entry.target.classList.remove('hidden')
            if (elDropdown) elDropdown.classList.add('menu-dropdown_item-hidden')
          }
        })
      }, options)

      let headings = document.querySelectorAll('.tags-nav__element')
      headings.forEach((heading) => observer.observe(heading))
    },
    hideDropdown() {
      const menu = document.querySelector(`.menu-dropdown`)
      if (menu) menu.classList.remove('menu-dropdown_visible')
    },
    cancelSearch($event: Event) {
      // this.focused = false
      this.query = ''
      this.$store.commit('search/changeQuery', '')
      this.$router.push('/')
    },
    onSearch(e: Event) {
      this.search(e)
    },
    changeTemplateType(templateType: any) {
      let previousActiveTemplateType = (<any>this).templateTypes.find((x: any) => x.isActive)
      if (!!previousActiveTemplateType) previousActiveTemplateType.isActive = false

      templateType.isActive = true
      this.$store.commit('search/changeTemplateType', templateType.id)
    },
    changeQuery() {
      this.$store.commit('search/changeQuery', this.query)
    },
    search($event: Event) {
      $event.stopPropagation()
      $event.preventDefault()
      this.$store.commit('search/changeQuery', this.query)
      const query = !!this.query ? this.query.toLowerCase().split(' ').join('-') : ''
      location.href = `/s/market/${query}`
    },
    getScrollPosition() {
      window.onscroll = () => {
        this.windowScrollPos = window.pageYOffset
        this.isWindowScrolled = this.windowScrollPos > 20
      }
    },
  },
})
