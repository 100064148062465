

































import Vue, { PropType } from 'vue';
import { TypeModal } from '../index.vue';

export default Vue.extend({
  data() {
    return {
      TypeModal
    };
  },
  props: {
    stepModal: String as PropType<TypeModal>,
    isLogin: Boolean
  },
  computed: {
    srcLogo() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/google-logo.svg';
    },
    srcAstronavt() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/astronavt.png';
    },
    srcsetAstronavt() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/astronavt@2x.png 2x';
    }
  }
});
