import { FullProjectCategoryDto } from '../utils/api';
type HeaderProjectTypeDto = FullProjectCategoryDto & {
  isActive: boolean;
  isSpecial?: boolean;
};
const state = () => ({
  projectTypes: [] as HeaderProjectTypeDto[]
});

const mutations = {
  changeProjectTypes(state: any, items: HeaderProjectTypeDto[]) {
    state.projectTypes = items;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
