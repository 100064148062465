var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"templates-grid-wrapper"},[_c('div',{staticClass:"banners-wrapper",attrs:{"id":"banner"}}),_vm._v(" "),(_vm.isClientMasonryRendered)?_c('div',{ref:"templatesContainerWrapper",staticClass:"templates-container-wrapper",class:{ 'templates-container-wrapper--ssr': !_vm.isMounted }},_vm._l((_vm.projectGrids),function(projectGrid){
var _obj;
return _c('div',{key:projectGrid.columns,class:[( _obj = {}, _obj[("masonry-templates-" + (projectGrid.columns))] = _vm.isRender, _obj )]},[_c('vue-masonry-wall',{attrs:{"items":_vm.projects,"options":{
          padding: _vm.masonryColumnPadding,
          width: _vm.masonryColumnWidth
        },"ssr":{
          columns: projectGrid.columns,
          padding: _vm.masonryColumnPadding
        }},on:{"append":_vm.loadMoreProjects},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
        var itemHeight = ref.itemHeight;
return [_c('ProjectItem',{attrs:{"project":item,"templateHeight":itemHeight,"isSsr":true}})]}}],null,true)})],1)}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"templates-container-wrapper",class:{ 'templates-container-wrapper--client': _vm.isMounted }},[_c('vue-masonry-wall',{attrs:{"items":_vm.projects,"options":{ padding: _vm.masonryColumnPadding, width: _vm.masonryColumnWidth }},on:{"append":_vm.loadMoreProjects},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
        var itemHeight = ref.itemHeight;
return [_c('ProjectItem',{attrs:{"project":item,"templateHeight":itemHeight}})]}}])})],1),_vm._v(" "),(_vm.showOverlay)?_c('div',{staticClass:"templates-overlay"}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }