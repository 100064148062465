












































import Vue from 'vue';
export default Vue.extend({
  computed: {
    urlImg(): string {
      return `${process.env.AMAZON_BUCKET_NAME}/Defaults/`;
    }
  }
});
