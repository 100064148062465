





















//TODO: join all to one component:
//1. src\components\Footer\AdditionalInfo.vue
//2. src\components\Footer\Footer.vue
//3. src\components\Login\Footer.vue
import Vue from 'vue'

import AirMenu from './Menu-short.vue'

export default Vue.extend({
  props: {
    type: String,
    isMain: Boolean,
  },
  components: {
    AirMenu,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    isDashboard(): boolean {
      const routePathParts = this.$route.path.split('/')
      if (routePathParts && routePathParts.length > 0) {
        return routePathParts[1] == 'dashboard'
      } else return false
    },
    rootUrl(): string {
      return this.$store.state.auth.isUserLoggedIn ? '/dashboard' : '/'
    },
  },
})
