<template>
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" id="next-icon">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.6833 6.68452C7.92328 6.92488 8.31268 6.92519 8.55304 6.6852L11.7432 3.49999L8.55304 0.314779C8.31268 0.0747948 7.92328 0.0750995 7.6833 0.315459C7.44331 0.55582 7.44362 0.945216 7.68398 1.1852L9.38643 2.88499L0.872813 2.88499C0.533157 2.88499 0.257813 3.16034 0.257813 3.49999C0.257813 3.83965 0.533157 4.11499 0.872813 4.11499L9.38643 4.11499L7.68398 5.81478C7.44362 6.05476 7.44331 6.44416 7.6833 6.68452Z"
      fill="#000"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
