






























































































































































































































import Vue from 'vue';
import { mapState } from 'vuex';
import ObjectKeys from '../../utils/types/ObjectKeys';
import emailValidator from '../../utils/email';
import { CredentialsDto, RegistrationUserDto, RegistrationSource, PublishedCommunityProjectDto } from '../../utils/api';
import apiClient, { handleFormErrors, handleHttpErrors } from '../../utils/apiClient';

declare module 'vue/types/vue' {
  interface Vue {
    $mautic: any;
  }
}
import { MauticOptions } from '../index.vue';
export default Vue.extend({
  data() {
    return {
      fullName: '',
      email: '',
      verifiedFullName: false,
      verifiedEmail: false,
      verifiedPassword: false,
      verifiedRepeatPassword: false,
      rulesPassword: { first: false, second: false, third: false },
      visiblePassword: false,
      visibleRepeatPassword: false,
      isProcessing: false,
      password: '',
      repeatPassword: '',
      inputErrors: {
        email: '',
        fullName: '',
        repeatPassword: '',
        password: ''
      } as ObjectKeys<string>
    };
  },
  computed: {
    ...mapState({
      communityProject: (state: any) => <PublishedCommunityProjectDto>state.page.communityProject,

      user: (state: any) => state.auth.user
    }),
    srcIconPasswordOpen() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/icon-password-open.svg';
    },
    srcIconPassword() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/icon-password.svg';
    },
    userCanLoginAndSignUp(): boolean {
      if (this.isLogin)
        return (
          this.verifiedEmail &&
          this.verifiedPassword &&
          this.inputErrors.email.length == 0 &&
          this.inputErrors.password.length == 0
        );

      return (
        this.verifiedFullName &&
        this.verifiedEmail &&
        this.verifiedPassword &&
        this.verifiedRepeatPassword &&
        this.inputErrors.email.length == 0 &&
        this.inputErrors.password.length == 0 &&
        this.inputErrors.fullName.length == 0 &&
        this.inputErrors.repeatPassword.length == 0
      );
    }
  },
  props: {
    isLogin: Boolean
  },
  async mounted() {
    this.email = '';
    this.fullName = '';
    if (!this.communityProject) return;
    const { currentUserAuthorized } = this.communityProject;

    if (currentUserAuthorized) {
      this.fullName = this.user.userName;
      this.email = this.user.email;
    }
  },
  methods: {
    check() {
      if (!this.fullName) {
        this.inputErrors.fullName = 'Please fill out all required fields';
        this.verifiedFullName = false;
      } else this.verifiedFullName = true;
      if (!this.email) {
        this.inputErrors.email = 'Please fill out all required fields';
        this.verifiedEmail = false;
      } else {
        this.inputErrors.email = emailValidator.isValid(this.email, true);
        this.verifiedEmail = this.inputErrors.email == '';
      }
    },
    changeVisibility(firstPassword: boolean) {
      if (firstPassword) {
        this.visiblePassword = !this.visiblePassword;
      } else {
        this.visibleRepeatPassword = !this.visibleRepeatPassword;
      }
    },
    parsePassword() {
      this.inputErrors.password = '';
      this.inputErrors.repeatPassword = '';
      this.verifiedPassword = false;
      this.rulesPassword.third = this.password.length >= 6;
      this.rulesPassword.second = /[!"#$%&'()*+,-./:;<=>?@[/\\//\]/^_`{|}~]/.test(this.password);
      this.rulesPassword.first = /[a-z]/g.test(this.password) && /[A-Z]/g.test(this.password);
    },
    checkPassword() {
      this.verifiedPassword = false;
      if (!this.password) {
        this.inputErrors.password = 'Please fill out all required fields';
      }
      if (!this.isLogin && this.password) {
        if (this.password.length < 6) {
          this.inputErrors.repeatPassword = 'The password must be longer than 6 characters';
        } /* else if (!/[0-9]/g.test(this.password)) {
          this.errorsPassword = 'The password must contain numbers'
        } else if (!this.rulesPassword.first) {
          this.errorsPassword = 'The password must contain capital letters'
        }  */ else {
          this.inputErrors.password = '';
          this.verifiedPassword = true;
        }
        if (!this.repeatPassword) {
          this.inputErrors.repeatPassword = 'Please fill out all required fields.';
          this.verifiedRepeatPassword = false;
        } else {
          if (this.password != this.repeatPassword) {
            this.inputErrors.repeatPassword = "Passwords don't match";
            this.verifiedRepeatPassword = false;
          } else {
            this.inputErrors.repeatPassword = '';
            this.verifiedRepeatPassword = true;
          }
        }
      } else {
        this.verifiedRepeatPassword = true;
        this.verifiedPassword = !this.inputErrors.password;
      }
    },
    async login() {
      this.check();
      if (!(this.verifiedPassword && this.verifiedEmail)) {
        return;
      }
      var userDto: CredentialsDto = {
        userName: this.email,
        password: this.password,
        rememberMe: false,
        redirectUrl: location.href
      };
      this.isProcessing = true;
      try {
        const { data } = await apiClient.auth.authLogin(userDto);
        this.$mautic.pageView({
          email: userDto.userName,
          points: 7,
          tags: ['logged-in-airtap']
        });
        this.$emit('authLogin', {
          isConfirmedEmail: data.isConfirmedEmail,
          isEmailConfirm: false
        });
        this.isProcessing = false;
        if (!!data.checkoutURL) {
          location.href = data.checkoutURL;
          return;
        }
      } catch (errorResponse) {
        handleHttpErrors(this.$store, this.$data, () => {
          var key: string;
          let errors = errorResponse.response.data;
          for (key in errors) {
            if (errors[key] == "The user doesn't confirmed email") {
              this.$emit('authLogin', {
                isConfirmedEmail: false,
                isEmailConfirm: true
              });
            } else {
              this.inputErrors.email = 'Incorrect email or password. Please try again.';
              this.inputErrors.password = 'Incorrect email or password. Please try again.';
            }
          }
        });
      }
    },
    async createAccount() {
      if (this.isLogin) {
        this.login();
        return;
      }
      this.check();

      if (!(this.verifiedPassword && this.verifiedEmail && this.verifiedFullName)) {
        return;
      }

      var data: RegistrationUserDto = {
        email: this.email,
        fullName: this.fullName,
        password: this.password,
        redirectUrl: location.href,
        source: RegistrationSource.Submission
      };
      this.isProcessing = true;
      try {
        await apiClient.auth.authRegister(data);
        this.isProcessing = false;
        let options: MauticOptions = {
          email: data.email,
          points: 10,
          tags: ['registered-airtap']
        };
        if (data.fullName) {
          options.firstname = data.fullName;
        }
        this.$mautic.pageView(options);
        this.$emit('authRegister');
      } catch (error) {
        handleFormErrors(error, this.inputErrors, this.$data, this.$store);
      }
    }
  }
});
