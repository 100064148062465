
































import Vue, { PropType } from 'vue';
import { CommunityActionButtonType, PublishedCommunityProjectDto } from '../../utils/api';
import { mapState } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapState({
      communityProject: (state: any) => <PublishedCommunityProjectDto>state.page.communityProject
    }),
    srcAstronavt() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/astronavt.png';
    },
    srcsetAstronavt() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/astronavt@2x.png 2x';
    },
    srcFacebook() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/facebook-white.svg';
    },
    srcTwitter() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/tweet-white.svg';
    },
    linkShare(): string {
      return `https://www.facebook.com/sharer.php?u=${location.href}&amp;t=${this.communityProject.name}%20on%20@itmeocom:%20${location.href}`;
    },
    linkTweet(): string {
      return `https://twitter.com/intent/tweet?url=${location.href}&via=itmeoHQ`;
    }
  },
  data() {
    return {
      CommunityActionButtonType
    };
  },
  props: {
    typeActionButton: String as PropType<CommunityActionButtonType>,
    link: String
  }
});
