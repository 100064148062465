






















import Vue from 'vue';
import minBy from 'lodash.minby';
import { ObserveVisibility } from 'vue-observe-visibility';
/**
 * @param count number of columns to create
 * @returns {[{i: number, indexes: []},...]}
 */
const _createNewColumns = (count: number): { i: number; indexes: number[]; height: number }[] => {
  const columns = [];
  for (let i = 0; i < count; i++) {
    columns.push({ i: i, indexes: [], height: 0 });
  }
  return columns;
};
export default Vue.extend({
  name: 'VueMasonryWall',
  directives: {
    'observe-visibility': ObserveVisibility
  },
  props: {
    /**
     * Array of items to add into masonry
     */
    items: {
      type: Array,
      required: true
    },
    /**
     * Options to config masonry.
     *
     * {
     *     width: 300,
     *     padding: {
     *         default: 12,
     *         1: 6,
     *         2: 8,
     *     },
     *     throttle: 300
     * }
     */
    options: {
      type: Object,
      required: false
    },
    /**
     * SSR has no clue what is the size of your height of your element or width of the browser.
     * You can however guess based on user-agent: https://github.com/nuxt-community/device-module
     * This param allow you to preload a config for SSR rendering, it will distribute your items into all columns evenly.
     *
     * Once the client is mounted, it will redraw if the config is different from SSR.
     *
     * {
     *     column: 2
     * }
     */
    ssr: {
      type: Object,
      required: false
    }
  },
  data() {
    const count = this.ssr && this.ssr.columns;
    if (!count) {
      return {
        columns: [],
        cursor: 0,
        ready: false
      };
    }
    const columns = _createNewColumns(count);
    for (let i = 0; i < this.items.length; i++) {
      const column = columns[i % count];
      column.indexes.push(i);
      column.height += (<any>this).calculateItemHeight(this.items[i]);
    }
    return {
      columns: columns,
      cursor: this.items.length,
      ready: true
    };
  },
  /**
   * For detecting browser resize event to redraw the columns.
   */
  mounted() {
    if (this.ssr) {
      // console.log(
      //   `[masonry mounted]: ssr columns ${this.ssr.columns}, current length: ${this.columns.length}`
      // )
    }
    if (!this.ssr) this.$resize();
    window.addEventListener('resize', this.$resize);
  },
  /**
   * Remove resize event listener
   */
  beforeDestroy() {
    window.removeEventListener('resize', this.$resize);
  },
  computed: {
    /**
     * Options with default override if not given
     *
     * @private
     */
    _options(): any {
      const options = this.options;
      return {
        width: (options && options.width) || 300,
        padding: {
          default: (options && options.padding && options.padding.default) || 12
        },
        throttle: (options && options.throttle) || 300
      };
    },
    /**
     * Style of wall, column and item for padding
     * @private
     */
    _style(): any {
      let padding = this.options && this.options.padding;
      if (padding && typeof padding != 'number') {
        padding = this.options.padding[this.columns.length] || this._options.padding.default;
      }
      return {
        wall: {
          margin: `-${padding}px`
        },
        lane: {
          paddingLeft: `${padding}px`,
          paddingRight: `${padding}px`
        },
        item: {
          paddingTop: `${padding}px`,
          paddingBottom: `${padding}px`
        }
      };
    }
  },
  methods: {
    //moved from mounted, maybe it was sense
    $resize() {
      const columnCount = this._getColumnSize();
      // console.log('redraw', { columnCount, columnLength: this.columns.length })
      if (this.columns.length !== columnCount) {
        this.redraw();
      }
    },
    /**
     * Redraw masonry
     */
    redraw() {
      this.ready = false;
      this.columns.splice(0);
      this.columns.push(..._createNewColumns(this._getColumnSize()));
      this.ready = true;
      this._fill();
    },
    /**
     * calculateItemHeight by scroll width
     */
    calculateItemHeight(item: any) {
      const scrollWidth = this._getScrollWidth();
      const columnWidth = scrollWidth / this._getColumnSize() - this.options.padding * 2;
      return item.width > 0 ? (columnWidth * item.height) / item.width : item.height;
    },
    /**
     *
     * @returns {number}
     * @private internal component use
     */
    _getScrollWidth() {
      let scrollWidth = 0;

      if (this.ssr && this.ssr.columns) scrollWidth = this.ssr.columns * this.options.width; // we need to receive column width from client to server for calculating height

      if ((<any>process).client) {
        const wall = <any>this.$refs.wall;
        if (wall && wall.scrollWidth) scrollWidth = wall.scrollWidth;
      }

      return scrollWidth;
    },
    /**
     *
     * @returns {number}
     * @private internal component use
     */
    _getColumnSize() {
      if ((<any>process).client) {
        if (window && window.innerWidth >= 400 && window.innerWidth <= 768) {
          return 2;
        }
      }

      // console.log('masonry scrollWidth', {
      //   wallScrollWidth: this.$refs.wall.scrollWidth,
      //   optionsScrollWidth: this._options.scrollWidth,
      //   width: this._options.width,
      //   length,
      // })
      let scrollWidth = this._getScrollWidth();
      let length = Math.round(scrollWidth / this.options.width);
      if (length < 1) return 1;
      // console.log(
      //   `[masonry]: columns ${this.ssr.columns}, current length: ${length}, ${scrollWidth}`
      // )
      return length;
    },
    /**
     * Add items into masonry columns, items are added to the shortest column first.
     *
     * @private internal component use
     */
    _fill() {
      if (!this.ready) return;

      if (this.cursor >= this.items.length) {
        // Request for more items
        this.$emit('append');
        return;
      }
      // Keep filling until no more items
      this.$nextTick(() => {
        this.__newNextTickByColumnHeight();
      });
    },
    /**
     * Add item to one of columns
     *
     * @private internal component use
     */
    __newNextTickByColumnHeight() {
      const item = this.items[this.cursor];
      if (item) {
        const column = minBy(this.columns, (el: any) => el.height);
        if (column) {
          column.indexes.push(this.cursor);
          const itemHeight = this.calculateItemHeight(item);
          // console.log(
          //   this.columns
          //     .map(
          //       (current, index) =>
          //         `column ${index}: ${current.indexes.length}, ${current.height}`
          //     )
          //     .join(', '),
          //   `, ${item.id}:${item.height}, ${itemHeight}`
          // )
          column.height += itemHeight;

          this.cursor++;
        }
      }
      this._fill();
    }
  }
});
