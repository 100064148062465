

















































import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { AccountDto, PictureType, ProjectType } from '../../utils/api';
import apiClient, { handleHttpErrors, SavingMasonryProjectItemDto } from '../../utils/apiClient';
import { createProjectUrl, createPicturesHtml } from '../../utils/itmeo/create-project-url';

import ProjectPreviewButton from './Project-preview-button.vue';
import { debounce } from 'lodash';

export default Vue.extend({
  components: {
    ProjectPreviewButton
  },
  props: {
    project: {
      type: Object as PropType<SavingMasonryProjectItemDto>
    },
    templateHeight: {
      type: Number,
      default: () => 0
    },
    isSsr: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState({
      isUserLoggedIn: (state: any) => <boolean>state.auth.isUserLoggedIn,
      user: (state: any) => <AccountDto>state.auth.user
    }),
    mainImage(): any {
      if (this.project.pictures && !!this.project.pictures.length) {
        let filterDesktopImage = this.project.pictures.find(
          el => el.type === PictureType.Desktop && el.isFeed && el.isCommon
        );

        return filterDesktopImage;
      }

      return '';
    },
    projectUrl(): string {
      return this.project.url || '';
    },
    pictures(): any[] {
      return createPicturesHtml(this.project.pictures, true);
    }
  },
  data() {
    const isProjectSaved = this.project.isSaved || false;
    return {
      ProjectType,
      savedProject: { isProjectSaved: isProjectSaved },
      downloadProgress: 0,
      isDownloadStarted: false,
      projectHasLike: { hasLike: this.project.isSaved }
    };
  },
  methods: {
    changeSaved(isSaved: boolean) {
      //так лучше не делать -> this.template.isSaved = isSaved;
      //происходит перерендер всех шаблонов
      //возможно в key нужно передавать id шаблона
      if (!this.isUserLoggedIn) {
        this.$router.push({
          path: '/register',
          query: { redirectUrl: window.location.pathname }
        });
        return;
      }
      this.savedProject.isProjectSaved = isSaved;
      this.toggleProjectLike(this.project, this.projectHasLike, this.$store, this.savedProject);
    },
    toggleProjectLike: debounce(
      (project: SavingMasonryProjectItemDto, projectHasLike: any, $store: any, savedProject: any) => {
        if (projectHasLike.hasLike == savedProject.isProjectSaved) return;
        apiClient.profiles
          .profilesToggleProjectLike(project.authorAlias, project.id)
          .then(() => {
            projectHasLike.hasLike = savedProject.isProjectSaved;
          })
          .catch(() => {
            handleHttpErrors($store, null, () => {
              savedProject.isProjectSaved = !savedProject.isProjectSaved;
            });
          });
      },
      800
    ),
    moveToTemplatePage() {
      this.$router.push(this.projectUrl);
    }
  }
});
