

















































































import Vue, { PropType } from 'vue';
import { CommunityActionButtonType, PublishedCommunityProjectDto } from '../../utils/api';
import { TypeModal } from '../index.vue';
import { mapState } from 'vuex';
import EmailConfirmBlock from './EmailConfirmBlock.vue';
import ActionPerformedBlock from './ActionPerformedBlock.vue';
import AuthorizationBlock from './AuthorizationBlock.vue';
import AuthorizationTypeBlock from './AuthorizationTypeBlock.vue';
import SendMessagePopup from './SendMessagePopup.vue';

export default Vue.extend({
  components: {
    EmailConfirmBlock,
    ActionPerformedBlock,
    AuthorizationBlock,
    AuthorizationTypeBlock,
    SendMessagePopup
  },
  computed: {
    ...mapState({
      communityProject: (state: any) => <PublishedCommunityProjectDto>state.page.communityProject
    }),
    srcClose() {
      return process.env.AMAZON_BUCKET_NAME + '/Defaults/close.svg';
    }
  },
  props: {
    typeActionButton: String as PropType<CommunityActionButtonType>,
    stepModal: String as PropType<TypeModal>,
    link: String,
    isLogin: Boolean,
    srcArrowLeft: String
  },
  data() {
    return {
      CommunityActionButtonType,
      TypeModal
    };
  },
  methods: {
    changestepModal(type: any) {
      this.$emit('changestepModal', type);
    },
    authLogin(date: any) {
      this.$emit('authLogin', date);
    }
  }
});
