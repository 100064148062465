





































import Vue from "vue";
import Storage from "../../utils/itmeo/storage";

export default Vue.extend({
  props: {
    isSaved: {
      type: Boolean,
      default: () => false
    },
    projectId: {
      type: Number,
      default: () => 0
    }
  },
  watch: {
    isSaved(val) {
      // console.log('is saved: ', val)
    }
  },
  methods: {
    save($event: Event) {
      $event.preventDefault();
      $event.stopPropagation();
      if (!this.isSaved) {
        Storage.addFavoriteTemplate(this.projectId);
      } else {
        Storage.removeFavoriteTemplate(this.projectId);
      }
      if (!this.isSaved) {
        //this.setHeartAnimationStyles();
      }
      this.$emit("changeSaved", !this.isSaved);
    },
    setHeartAnimationStyles() {
      if (window.innerWidth < 992) return;
      const heartRect = (<any>this.$refs.heart).getBoundingClientRect();
      const headerHeart = document.getElementsByClassName("favorite-btn")[0];
      const flyingHeart = <HTMLDivElement>(
        document.getElementsByClassName("heart-fav-icon")[0]
      );
      const headerHeartRect = headerHeart.getBoundingClientRect();
      flyingHeart.classList.remove("active");
      flyingHeart.style.top = "0";
      flyingHeart.style.left = "0";

      flyingHeart.style.top = `${heartRect.top - 2}px`;
      flyingHeart.style.left = `${heartRect.left}px`;

      setTimeout(() => {
        flyingHeart.classList.add("active");
        flyingHeart.style.top = `${headerHeartRect.top - 2}px`;
        flyingHeart.style.left = `${headerHeartRect.left + 3}px`;
      }, 2000);

      setTimeout(() => {
        flyingHeart.classList.remove("active");
      }, 3200);
    }
  }
});
