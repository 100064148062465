





























import Vue from "vue";
import apiClient, { handleHttpErrors } from "../../utils/apiClient";
import { mapState } from "vuex";
import { ReceiveMessageDto } from "../../utils/api";

export default Vue.extend({
  computed: {
    ...mapState({
      user: (state: any) => state.auth.user
    })
  },

  data() {
    return {
      isProcessing: false,
      isSuccessed: false,
      errors: "",
      message: "",
      messageCount: 0
    };
  },
  methods: {
    changeInputValue(event: any) {
      this.messageCount = event.currentTarget.value.length;
      (<any>this.message) = event.currentTarget.value;
    },
    sendMessage() {
      if (this.isSuccessed) {
        this.closeModal();
        return;
      }
      var data: ReceiveMessageDto = {
        message: this.message
      };

      this.isProcessing = true;
      apiClient.profiles
        .profilesSendMessage(this.user.userName, data)
        .then(() => {
          this.isProcessing = false;
          this.isSuccessed = true;
          this.$emit("sendMessage");
        })
        .catch(error => {
          handleHttpErrors(this.$store, this.$data);
        });
    },
    closeModal() {
      this.$store.commit("ui/SET_POPUP_SETTINGS", {
        active: false
      });
    }
  }
});
