import CookieStorage from "./CookieStorage";
import LocalStorage from "./LocalStorage";

export class Storage {
  FAVORITE_TEMPLATES_KEY = "favoriteTemplates";
  IS_NOT_LAUNCH_FIRST_TIME = "isNotLaunchFirstTime";
  TOTAL_PAGES_VIEWS = "totalPagesViews";

  /**
   * Create a storage.
   * @param {LocalStorage} localStorage - The localStorage.
   * @param {CookieStorage} cookieStorage - The cookieStorage.
   */
  private __local: LocalStorage;
  private __cookie: CookieStorage;
  constructor(localStorage: LocalStorage, cookieStorage: CookieStorage) {
    this.__local = localStorage;
    this.__cookie = cookieStorage;
  }

  /**
   * get total pages views for user
   * @returns {number} total pages
   */
  getUserTotalPagesViews() {
    let totalPages = parseInt(this.__local.get(this.TOTAL_PAGES_VIEWS) || "0");
    totalPages++;

    this.__local.set(this.TOTAL_PAGES_VIEWS, totalPages.toString());

    return totalPages;
  }

  addFavoriteTemplate(id: number) {
    const templates = this.getFavoriteTemplates();
    templates.push(id);
    this.__cookie.createCookie(
      this.FAVORITE_TEMPLATES_KEY,
      JSON.stringify(templates),
      730
    );
  }

  removeFavoriteTemplate(id: number) {
    let templates = this.getFavoriteTemplates();

    const index = templates.indexOf(id);
    templates.splice(index, 1);

    this.__cookie.createCookie(
      this.FAVORITE_TEMPLATES_KEY,
      JSON.stringify(templates),
      730
    );
  }

  getIsNotLaunchFirstTime() {
    return this.__local.get(this.IS_NOT_LAUNCH_FIRST_TIME) == "true";
  }

  setIsNotLaunchFirstTime() {
    this.__local.set(this.IS_NOT_LAUNCH_FIRST_TIME, "true");
  }

  getIsShowEmailConfirmation() {
    return this.__local.get("emailShow");
  }

  setIsShowEmailConfirmation() {
    this.__local.set("emailShow", "show");
  }

  getIsShowCookiePopup() {
    return this.__local.get("cookie");
  }

  setIsShowCookiePopup() {
    this.__local.set("cookie", "isCookieSet");
  }

  getFavoriteTemplates(request?: any) {
    if ((<any>process).server) {
      return this.getFavoriteTemplatesFromRequest(request);
    } else {
      let templates = this.__cookie.getCookie(this.FAVORITE_TEMPLATES_KEY);
      templates = templates ? JSON.parse(templates) : [];

      return templates;
    }
  }

  getFavoriteTemplatesFromRequest(request?: any) {
    if (!request || !request.headers || !request.headers.cookie) {
      if (
        request &&
        request.headers &&
        ((request.headers["user-agent"] &&
          request.headers["user-agent"].includes("Better Uptime Bot")) ||
          (request.headers["cdn-loop"] &&
            request.headers["cdn-loop"] == "cloudflare"))
      ) {
        return [];
      }

      console.warn(
        "[getTemplateFavoritesFromRequest]: request is null in async data",
        request && request.headers ? request.headers : null
      );

      return [];
    }

    const { cookie } = request.headers;
    const templateRegexp = /favoriteTemplates=(\[[^\]]*]+)/;
    const matchesTemplateArrayString = templateRegexp.exec(cookie);
    const tempaltesArrayString =
      matchesTemplateArrayString && matchesTemplateArrayString.length > 0
        ? matchesTemplateArrayString[1]
        : "[]";
    return JSON.parse(tempaltesArrayString);
  }
}
