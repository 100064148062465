


















































import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';

import { MasonryProjectItemDto } from '../../utils/api';

import VueMasonryWall from './masonry/VueMasonryWall.vue';
import ProjectItem from './Project-item.vue';

export default Vue.extend({
  name: 'ProjectsFadeGrid',
  props: {
    projects: {
      type: Array as PropType<MasonryProjectItemDto[]>,
      default: () => []
    },
    getNextPage: {
      type: Function,
      default: () => null
    }
  },
  components: {
    VueMasonryWall,
    ProjectItem
  },
  data() {
    return {
      isRender: true,
      isMounted: true,
      isClientMasonryRendered: true,
      masonryColumnWidth: 360,
      masonryColumnPadding: 20,
      projectGrids: [{ columns: 6 }, { columns: 5 }, { columns: 4 }, { columns: 3 }, { columns: 2 }]
    };
  },
  mounted() {
    let clientColumns = this.getClientColumns();
    // console.log('[template-fade-grid]: clientColumns', clientColumns)
    while (this.projectGrids.some(x => x.columns != clientColumns)) {
      let removeProjectGridIndex = this.projectGrids.findIndex(x => x.columns != clientColumns);
      this.projectGrids.splice(removeProjectGridIndex, 1);
    }

    this.isRender = false;
    // console.log('[template-fade-grid]: projectGrids', this.projectGrids)
    setTimeout(() => {
      this.isMounted = false;
      setTimeout(() => {
        this.isClientMasonryRendered = false;
      }, 500);
    }, 1000);
  },
  computed: {
    ...mapState({
      isUserAuth: (state: any) => state.auth.isUserLoggedIn
    }),
    showOverlay(): boolean {
      return !this.isUserAuth && this.$route.name !== 'favorites' && !!this.projects && !!this.projects.length;
    }
  },
  methods: {
    getClientColumns() {
      if (!(<any>process).client) {
        return 0;
      }
      if (this.$route.name === 'profile-downloads') {
        if (window && window.innerWidth > 2218) {
          return 5;
        }
        if (window && window.innerWidth > 1559 && window.innerWidth <= 2108) {
          return 4;
        }
        if (window && window.innerWidth > 1160 && window.innerWidth <= 1559) {
          return 3;
        }
        if (window && window.innerWidth > 320 && window.innerWidth <= 1160) {
          return 2;
        }
      }
      if (window && window.innerWidth > 2218) {
        return 6;
      }
      if (window && window.innerWidth > 1669) {
        return 5;
      }
      if (window && window.innerWidth > 1310 && window.innerWidth <= 1669) {
        return 4;
      }
      if (window.innerWidth > 902 && window && window.innerWidth <= 1310) {
        return 3;
      }
      if (window && window.innerWidth >= 320 && window.innerWidth <= 902) {
        return 2;
      }
      return 0;
    },
    loadMoreProjects() {
      // console.log('[template-fade-grid]: getNextPage loadMoreProjects')
      if (!this.getNextPage) {
        return;
      }

      this.getNextPage();
    }
  }
});
