import axios, { AxiosResponse } from 'axios';
import { IPublishAPI, MemberDto, ServiceInterfaceType } from '../components/Publish/types';
import { RegistrationUserDto, CredentialsDto } from './api';
const isDevelopment = process.env.NODE_ENV === 'development';

function createClient() {
  const config = {
    baseURL: isDevelopment ? '' : process.env.API_URL,
    withCredentials: true
  };
  return axios.create(config);
}

export class PublishAPI implements IPublishAPI {
  type = ServiceInterfaceType.PublishAPI;
  async postMailchimpMember(projectId: number, payload: MemberDto) {
    try {
      const client = createClient();
      await client.post(`/api/publish/${projectId}/mailchimp/subscribe`, payload);
    } catch (error) {
      const { message } = error;
      console.log('[postMailchimpMember]:error', message ? message : error);
    }
  }
}
