













import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  props: {
    className: {
      type: String,
      default: '',
    },
    isDark: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      //
    }
  },
  computed: {
    ...mapState({
      isUserAuth: (state: any) => state.auth.isUserLoggedIn,
    }),
    href(): string {
      return this.isUserAuth ? '/dashboard' : '/register'
    },
  },
  methods: {
    redirectTo() {
      location.href = this.href
    },
  },
})
